import Layout from '../Layout';
import { LanguageContext } from '../Components/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import handleTransformLang from '../functions/handleTransformLang'
import ProductsData from '../lang/products.json'
import Product from '../Components/Product'

import LangData from '../lang/pages/products.json'

export default function Products() {
    return (
        <Layout headData={LangData.head}>
            <InnerPage />
        </Layout>
    )
}

function InnerPage() {
    function getIterableData() {
        return Object.values(ProductsData).map(
            (product) => handleTransformLang(product)
        )
    }
    const { language } = useContext(LanguageContext);
    const [products, setProducts] = useState(getIterableData());
    useEffect(() => {
        setProducts(getIterableData())
    }, [language])

    const [content, setContent] = useState(handleTransformLang(LangData));
    
    useEffect(() => {
        setContent(handleTransformLang(LangData))
    }, [language])

    return (
        <>
            <div className="py-14 md:py-20 bg-gray-900 pb-20 flex flex-col items-center justify-center dark:bg-gradient-to-bl dark:from-indigo-950 dark:via-[#140b2a] dark:to-gray-950 bg-gradient-to-bl from-gray-200 via-gray-100 to-white">
                <div className='mx-8 md:mx-24 lg:mx-28 xl:mx-36 2xl:mx-44 max-w-5xl py-12'>
                    <h1 className='text-gray-800 dark:text-white text-3xl md:text-3xl lg:text-4xl xl:text-4xl pb-10 font-semibold pl-4'>
                        {content.header}
                    </h1>

                    <div className="bg-blue-100 dark:bg-indigo-950 grid overflow-hidden shadow-xl dark:shadow-black rounded-3xl grid-cols-1 lg:grid-cols-2 ">

                        {products.map((product, index) =>

                            <Product key={index}
                                title={product.title}
                                description={product.description}
                                logo={product.logo}
                                darklogo={product.darklogo}
                                link={product?.link}
                                readMore={content.read_more}
                            />
                        )}

                    </div>
                </div>
            </div>
        </>

    );
}
