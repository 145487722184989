
export default function Capability({ title, description, imageSrc }) {

    return (
        <div className="w-full sm:w-1/2 lg:w-1/2 ">
            <div className="rounded-xl transition ease-in delay-100 hover:bg-gray-300/50 dark:hover:dark:bg-gray-700/50 m-1  ">
                <div className="flex flex-col items-center justify-between px-8 py-10 lg:py-14">
                    <img
                        src={imageSrc}
                        loading="lazy"
                        className="w-24 pb-6"
                        width={512}
                        height={512}
                        alt="burger illustration"
                    />
                    <p className="text-2xl text-center font-semibold text-gray-800 dark:text-gray-100 transition py-2 ">
                        {title}
                    </p>

                    <p className="text-center md:text-base lg:text-lg xl:text-lg text-gray-700 dark:text-gray-300">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}
