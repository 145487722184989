import { useState, useEffect, Fragment } from 'react'
import { useContext } from 'react';
import Logo from './Components/Logo'
import { Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import NavLink from './Components/NavLink'
import NavbarLink from './Components/Navbarlink'
import DarkModeButton from './Components/DarkModeButton'
import LangSwitcher from './Components/LangSwitcher'
import { LanguageContext } from './Components/LanguageContext';
import NavLang from './lang/pages/nav.json'
import handleTransformLang from './functions/handleTransformLang'

export default function Nav() {
    const [showingNavigationDropdown, setShowingNavigationDropdown] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const { language } = useContext(LanguageContext);

    const [content, setContent] = useState(handleTransformLang(NavLang));
    
    useEffect(() => {
        setContent(handleTransformLang(NavLang))
    }, [language])

    useEffect(() => {

        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (((15 > currentScrollPos - prevScrollPos) && (-15 < currentScrollPos - prevScrollPos))) {
                if (100 >= currentScrollPos) {
                    setIsVisible(true);
                }
            }
            else if ((currentScrollPos > prevScrollPos)) {
                setIsVisible(false);
                setShowingNavigationDropdown(false);

            }
            else if ((currentScrollPos < prevScrollPos)) {
                setIsVisible(true);
                setShowingNavigationDropdown(false);

            }
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <nav className={`drop-shadow-md z-50 px-6 lg:px-8 backdrop-blur-md bg-white/50 dark:bg-[#000018]/50 fixed inset-x-0 top-0  transition-all duration-500
        ${isVisible ? "transform translate-y-0" : "-translate-y-full"}`}>
            <div className="mx-auto max-w-5xl container ">
                <div className="flex justify-between">
                    <div className="flex flex-1 justify-between ">
                        <div className="relative shrink-0 flex items-center" >
                            <Link to='/' className='z-50 py-2'>
                                <Logo className=" w-auto fill-current text-gray-800 dark:text-gray-200" />
                            </Link>
                            {/* dark:invert dark:hue-rotate-[210deg] block */}
                        </div>
                        <div className='flex items-center'>
                            <ul className='flex justify-end my-6 font-light hidden md:flex '>
                                <li className='mx-4 last:mr-0'>
                                    <NavbarLink to={'/'} >
                                        {content.home}
                                    </NavbarLink>
                                </li>
                                <li className='mx-4 last:mr-0'>
                                    <NavbarLink to={'/products'} >
                                        {content.products}
                                    </NavbarLink>
                                </li>
                                <li className='mx-4 last:mr-0'>
                                    <NavbarLink to={'/about'} >
                                        {content.about}
                                    </NavbarLink>
                                </li>
                                <li className='mx-4 last:mr-0'>
                                    <NavbarLink to={'/contact'} >
                                        {content.contact}
                                    </NavbarLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='mx-2 md:ml-5 flex justify-center items-center'>
                        <DarkModeButton />
                    </div>
                    <div className='mx-2 md:ml-5 flex justify-center items-center'>
                        <LangSwitcher />
                    </div>
                    <div className="-mr-2 flex items-center md:hidden">
                        <button
                            onClick={() => setShowingNavigationDropdown((previousState) => !previousState)}
                            className="inline-flex items-center justify-center p-2 rounded-full text-gray-400 dark:text-gray-200  focus:outline-none focus:bg-gray-100/50 dark:focus:bg-gray-700/50  transition duration-150 ease-in-out">
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path
                                    className={!showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                                <path
                                    className={showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>

                    </div>
                </div>
            </div>
            <Transition
                as={Fragment}
                show={showingNavigationDropdown}
                enter="transform transition duration-[400ms] ease-linear"
                enterFrom="opacity-0 translate-x-full scale-0"
                enterTo="opacity-100 translate-x-0 scale-100"
                leave="transform duration-[200ms] transition ease-linear"
                leaveFrom="opacity-100 translate-x-0 scale-100"
                leaveTo="opacity-0 translate-x-full scale-0"
            >
                <div className="pt-2 pb-3  px-6 md:hidden">
                    <ul className='my-6 font-light'>
                        <li className=' border-b border-gray-400 dark:border-gray-600 p-3 last:border-none'>
                            <NavLink onClick={() => setShowingNavigationDropdown(false)} to={'/'}  >
                                {content.home}
                            </NavLink>
                        </li>
                        <li className=' border-b border-gray-400 dark:border-gray-600 p-3 last:border-none'>
                            <NavLink onClick={() => setShowingNavigationDropdown(false)} to={'/products'}  >
                                {content.products}
                            </NavLink>
                        </li>
                        <li className=' border-b border-gray-400 dark:border-gray-600 p-3 last:border-none'>
                            <NavLink onClick={() => setShowingNavigationDropdown(false)} to={'/about'} >
                                {content.about}
                            </NavLink>
                        </li>
                        <li className=' border-b border-gray-400 dark:border-gray-600 p-3 last:border-none'>
                            <NavLink onClick={() => setShowingNavigationDropdown(false)} to={'/contact'} >
                                {content.contact}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </Transition>
        </nav>

    )
}