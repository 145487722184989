
function SvgComponent({ className }) {
  return (
    <>
      <img
        className={`inline dark:hidden  h-14 ` + className}
        src='/logo.svg'
      />
      <img
        className={`hidden dark:inline  h-14 ` + className}
        src='/logodark.svg'
      />
    </>
  )
}

export default SvgComponent
