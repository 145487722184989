import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import React from 'react'
import ReactDOM from 'react-dom/client'
import Home from './Pages/Home.jsx'
import Contact from './Pages/Contact.jsx'
import About from './Pages/About.jsx'
import Products from './Pages/Products.jsx'
import ProductReview from './Pages/Products/ProductReview.jsx'
import ErrorPage from './Pages/ErrorPage.jsx'
import './css/index.css'
import ProductsData from "./lang/products.json";

let productPagePaths = []
async function loadProductData() {

  for (const key of Object.keys(ProductsData)) {
    if (ProductsData[key].link) {
      try {
        const data = await import(`./lang/products/${key}.json`);
        const jsonData = data.default;

        productPagePaths.push({
          path: ProductsData[key].link,
          element: (
            <ProductReview data={jsonData} />
          ),
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

}

async function setupRouterAndRender() {
  await loadProductData();
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "products",
        element: <Products />,
      },
      ...productPagePaths

    ]
  );

  ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  )

}
setupRouterAndRender()