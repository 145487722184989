import Layout from '../Layout';
import MainSection from '../Partials/MainSection'
import Solution from '../Partials/Solution'
import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Components/LanguageContext';
import LangData from '../lang/pages/home.json'
import handleTransformLang from '../functions/handleTransformLang'
import capabilitiesData from '../lang/capabilities.json'
import Capability from '../Components/Capability'
import solutionsData from '../lang/solutions.json'

export default function Home() {

    return (
        <Layout headData={LangData.head}>
            <InnerPage />
        </Layout>
    )
}
function InnerPage() {

    const { language } = useContext(LanguageContext);
    const [capabilities, setCapabilities] = useState(getIterableData(capabilitiesData));
    const [solutions, setSolutions] = useState(getIterableData(solutionsData));
    const [main, setMain] = useState(handleTransformLang(LangData.main));
    const [solutionsHead, setSolutionsHead] = useState(handleTransformLang(LangData.solutionsH));
    const [capabilitiesHead, setCapabilitiesHead] = useState(handleTransformLang(LangData.capabilitiesH));

    function getIterableData(data) {
        return Object.values(data).map(
            (product) => handleTransformLang(product)
        )
    }
    useEffect(() => {
        setCapabilities(getIterableData(capabilitiesData))
        setSolutions(getIterableData(solutionsData))
        setMain(handleTransformLang(LangData.main))
        setSolutionsHead(handleTransformLang(LangData.solutionsH))
        setCapabilitiesHead(handleTransformLang(LangData.capabilitiesH))
    }, [language])

    return (
        <>
            <div className='pb-20 dark:bg-gradient-to-bl dark:from-indigo-950 dark:via-[#140b2a] dark:to-gray-950 bg-gradient-to-bl from-gray-200 via-gray-100 to-white'>
                <section className='flex flex-col items-center justify-center overflow-hidden bg-blue-100 max-h-full min-h-screen dark:bg-indigo-950 pt-32 pb-20'>
                    <div className='mx-8 md:mx-24 lg:mx-28 xl:mx-36 2xl:mx-44 max-w-5xl'>
                        <MainSection
                            title={main.title}
                            description={main.description}
                            src={main.src}
                        />
                    </div>
                </section>
                <section className='flex flex-col items-center justify-center overflow-hidden pt-12 md:py-16 '>
                    <div className=' mx-8 md:mx-24 lg:mx-28 xl:mx-36 2xl:mx-44 max-w-5xl'>
                        <div className=' mx-8 flex justify-center'>
                            <header className=' font-semibold text-gray-800 dark:text-white text-3xl md:text-3xl lg:text-4xl xl:text-4xl pb-6 md:pb-12'>
                                {solutionsHead.head}
                            </header>
                        </div>
                        {Object.values(solutions).map((solution, index) =>
                            <Solution key={index}
                                header={solution.title}
                                description={solution.description}
                                direction={index % 2 === 1 ? "right" : "left"}
                                src={solution?.src}
                                color="bg-blue-100 dark:bg-indigo-950"
                            />
                        )}
                    </div>
                </section>
                <section className='flex flex-col items-center justify-center pt-12 md:py-16 '>
                    <div className='mx-8 md:mx-24 lg:mx-28 xl:mx-36 2xl:mx-44 max-w-5xl pt-12 bg-gray-400/20 shadow-xl dark:bg-gray-700/50  rounded-[20px] md:rounded-[40px] overflow-hidden'>
                        <div className='w-full'>
                            <div className='mx-8 flex justify-center'>
                                <header className='text-black dark:text-white mb-4 text-3xl md:text-3xl lg:text-4xl xl:text-4xl pb-6 md:pb-12'>
                                    {capabilitiesHead.head}
                                </header>
                            </div>
                            <div className="flex flex-wrap justify-center w-full px-6 pb-12  ">
                                {capabilities?.map((skill, index) =>

                                    <Capability key={index}
                                        title={skill.title}
                                        description={skill.description}
                                        imageSrc={skill.image}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
