
import { Link } from "react-router-dom"
export default function Product({ title, description, link, logo, darklogo, readMore }) {

    const lightlogo = (
        <img
            src={logo}
            loading="lazy"
            className="h-24 object-contain hidden dark:inline"
            width={512}
            height={512}
            alt="burger illustration"
        />
    )

    return (
        <>
            <div className="relative transition ease-in delay-100 hover:bg-blue-50/80 dark:hover:bg-indigo-900/50">
                <Link
                    to={link}
                    className=" text-gray-700 dark:text-gray-500 hover:text-primary dark:text-gray-400 dark:hover:text-gray-500"
                >
                    <div className=" space-y-8 px-8 py-10  h-full lg:py-14">
                        <div className="flex justify-center">
                            {(logo) && <div>
                                <img
                                    src={logo}
                                    loading="lazy"
                                    className="h-24 object-contain inline dark:hidden"
                                    width={512}
                                    height={512}
                                    alt="burger illustration"
                                />
                                {(darklogo) ?
                                    <img
                                        src={darklogo}
                                        loading="lazy"
                                        className="h-24 object-contain hidden dark:inline"
                                        width={512}
                                        height={512}
                                        alt="burger illustration"
                                    />

                                    : lightlogo}
                            </div>}
                        </div>
                        <div className="space-y-6">
                            <h3 className="text-center text-2xl font-semibold text-gray-800 dark:text-gray-100 transition ">
                                {title}
                            </h3>
                            <p className="text-gray-800 dark:text-gray-400">
                                {description}
                            </p>
                            {(link &&

                                <div className="text-sm ">{readMore}</div>

                            )}
                        </div>

                    </div>
                </Link>

            </div>
        </>
    )
}
