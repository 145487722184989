import Layout from '../Layout';
import { LanguageContext } from '../Components/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import Location from '../Components/Location';
import locationsData from '../lang/locations.json';

import LangData from '../lang/pages/contact.json'
import handleTransformLang from '../functions/handleTransformLang'

export default function Contact() {
    return (
        <Layout headData={LangData.head}>
            <InnerPage />
        </Layout>
    )
}
function InnerPage() {

    function getIterableData() {
        return Object.values(locationsData).map(
            (location) => handleTransformLang(location)
        )
    }

    const { language } = useContext(LanguageContext);
    const [locations, setLocations] = useState(getIterableData());

    const [content, setContent] = useState(handleTransformLang(LangData));
    
    useEffect(() => {
        setContent(handleTransformLang(LangData))
        setLocations(getIterableData())
    }, [language])

    return (
        <>
            <div className="py-14 md:py-20 bg-gray-900 pb-20 flex flex-col items-center justify-center dark:bg-gradient-to-bl dark:from-indigo-950 dark:via-[#140b2a] dark:to-gray-950 bg-gradient-to-bl from-gray-200 via-gray-100 to-white">
                <div className='mx-8 md:mx-24 lg:mx-28 xl:mx-36 2xl:mx-44 max-w-5xl py-12'>
                    <h1 className='text-gray-800 dark:text-white text-3xl md:text-3xl lg:text-4xl xl:text-4xl pb-10 font-semibold pl-4'>
                        {content.header}
                    </h1>
                    <section className="grid justify-center items-center gap-6 overflow-hidden lg:grid-cols-2 grid-cols-1 md:rounded-[30px] rounded-[15px] p-6 mx-auto bg-blue-100 dark:bg-indigo-950 shadow-xl dark:shadow-black  mb-12 p-4 md:p-8 lg:p-12">
                        <div className='flex flex-col justify-between h-full'>
                            <p className='py-4  text-gray-950/80 dark:text-white font-semibold text-xl md:text-2xl lg:text-3xl'>
                                {content.messageSection.header}
                            </p>
                            <p className='text-md md:text-lg lg:text-xl text-gray-700/75 dark:text-gray-400'>
                                {content.messageSection.text}
                            </p>
                        </div>
                        <form>
                            <div className="grid grid-cols-1 mt-4">
                                <div className='mt-4'>
                                    <label className="text-gray-900/50 dark:text-gray-500 " htmlFor="fullname">
                                        {content.messageSection.fullName}
                                    </label>
                                    <input
                                        id="fullname"
                                        type="text"
                                        
                                        className="border-0 border-b bg-transparent mt-2 dark:bg-indigo-950 block w-full py-2 text-gray-700 dark:text-gray-200 dark:border-gray-600 border-gray-900/50 focus:ring-indigo-950 focus:ring-opacity-40 focus:outline-none"
                                    />
                                </div>
                                <div className='mt-4'>
                                    <label
                                        className="text-gray-900/50 dark:text-gray-500"
                                        htmlFor="email"
                                    >
                                        {content.messageSection.email}

                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        className="border-0 border-b bg-transparent mt-2 dark:bg-indigo-950  block w-full py-2 text-gray-700 dark:text-gray-200 dark:border-gray-600 border-gray-900/50 focus:ring-indigo-950 focus:ring-opacity-40 focus:outline-none"
                                    />
                                </div>
                                <div className='mt-6'>
                                    <label className="text-gray-900/50 dark:text-gray-500" htmlFor="message">
                                        {content.messageSection.message}
                                    </label>
                                    <textarea
                                        id="message"
                                        className="w-full h-32 rounded-md dark:bg-indigo-950 bg-transparent border px-2 block w-full mt-2 py-2 text-gray-700 dark:text-gray-200 dark:border-gray-600 border-gray-900/50  focus:ring-indigo-950 focus:ring-opacity-40 focus:outline-none"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-end mt-6">
                                <button className="px-8 py-2.5 leading-5 text-black transition-colors duration-300 transform bg-gray-100 rounded-3xl hover:bg-gray-300 dark:hover:bg-gray-400 focus:outline-none focus:bg-gray-300">
                                    {content.messageSection.send}
                                </button>
                            </div>
                        </form>
                    </section>

                    {locations.map((location, index) =>

                        <Location key={index}
                            locationTitle={location.locationTitle}
                            locationDesc={location.locationDesc}
                            viewLargerMap={content.viewLargerMap}
                            mapHref={location.mapHref}
                            mapSrc={location.mapSrc}
                            imageSrc={location.imageSrc}
                        />
                    )
                    }

                </div>
            </div>
        </>

    )
}
