import { NavLink } from 'react-router-dom';
export default function LayoutLink({ active = false, className = '', children, to, ...props }) {
    return (
        <>
            <NavLink
                {...props}
                to={to}
                className={({ isActive }) =>
                    isActive
                        ? 'pb-2 font-bold text-[#0083CB] dark:text-[#00A7DF]'
                        : 'pb-2 text-gray-900 dark:text-gray-100'
                }
            >
                {children}
            </NavLink>
        </>
    );
}
