import Layout from '../Layout';
import { LanguageContext } from '../Components/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import Person from '../Components/Person'
import employeesData from '../lang/employees.json'

import LangData from '../lang/pages/about.json'
import handleTransformLang from '../functions/handleTransformLang'

export default function About() {
    return (
        <Layout headData={LangData.head}>
            <InnerPage />
        </Layout>
    )
}

function InnerPage() {
    function getIterableData() {
        return Object.values(employeesData).map(
            (employee) => handleTransformLang(employee)
        )
    }

    const { language } = useContext(LanguageContext);
    const [employees, setEmployees] = useState(getIterableData());
    const [content, setContent] = useState(handleTransformLang(LangData));
    
    useEffect(() => {
        setContent(handleTransformLang(LangData))
        setEmployees(getIterableData())
    }, [language])

    return (
        <>
            <div className="py-14 md:py-20 bg-gray-900 pb-20 flex flex-col items-center justify-center dark:bg-gradient-to-bl dark:from-indigo-950 dark:via-[#140b2a] dark:to-gray-950 bg-gradient-to-bl from-gray-200 via-gray-100 to-white">
                <div className='mx-8 md:mx-24 lg:mx-28 xl:mx-36 2xl:mx-44 max-w-5xl py-12'>
                    <h1 className='text-gray-800 dark:text-white text-3xl md:text-3xl lg:text-4xl xl:text-4xl pb-10 font-semibold '>
                        {content.header}
                    </h1>
                    <p className='text-gray-950/80 dark:text-white mb-10 text-base sm:text-lg '>
                        {content.description}
                    </p>
                    <p className='text-gray-800 dark:text-white text-3xl md:text-3xl lg:text-4xl xl:text-4xl pb-10 font-semibold text-center'>
                        {content.employees}
                    </p>
                    <ul className='flex flex-wrap justify-center w-full border-1  shadow-xl dark:shadow-black  bg-blue-100 dark:bg-indigo-950 md:rounded-[30px] rounded-[15px] py-12 px-4 md:px-8 lg:px-12 '>
                        {employees.map((employee, index) =>

                            <Person
                                key={index}
                                src={employee.src}
                                name={employee.name}
                                description={employee.description}
                                linkedin={employee.linkedin}
                            />
                        )
                        }

                    </ul>
                </div>
            </div>
        </>

    )
}
