
export default function Person({ name, description, src, linkedin }) {

    return (
        <li className="flex flex-col w-full md:w-1/2 lg:w-1/3 my-4">
            <div className='flex items-center justify-center'>
                <img
                    className=' aspect-square w-full h-full shrink-0 overflow-hidden  shadow-xl border dark:border-none rounded-full object-cover max-w-[12em] max-w-[12em]'
                    src={src} />
            </div>
            <header className="flex flex-col h-full text-center justify-between items-center">
                <div className="flex items-center ">
                    <h3 className=" text-gray-800 dark:text-gray-100 text-base md:text-base lg:text-lg xl:text-lg mb-2 my-4">
                        {name}
                    </h3>
                </div>
                <p className="text-gray-700/80 dark:text-gray-400 text-xs  md:text-sm lg:text-base ">
                    {description}
                </p>
                <div className="flex mt-4">
                    <a href={linkedin}
                        className=" mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                        aria-label="Github">

                        <svg className="w-6 h-6 lg:w-8 lg:h-8 fill-gray-900/80 dark:fill-current"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                            <g id="SVGRepo_iconCarrier">
                                <path
                                    d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 01-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 002 2.866v10.268a.88.88 0 00.885.866h10.226a.882.882 0 00.889-.866V2.865a.88.88 0 00-.889-.864z"
                                />
                            </g>
                        </svg>
                    </a>
                </div>
            </header>
        </li>

    )
}
