import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { LanguageContext } from './Components/LanguageContext.jsx';
import React from 'react'
import { useLocation } from 'react-router-dom';
import Nav from './Nav'
import NavLang from './lang/pages/nav.json'
import handleTransformLang from './functions/handleTransformLang.js'
import handleLangSwitch from './functions/handleLangSwitch.js'
import ProductsData from './lang/products.json'

export default function Layout({ children, headData = '' }) {

    let pathname = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function getIterableData() {
        return Object.values(ProductsData).map(
            (product) => handleTransformLang(product)
        )
    }
    const [language, setLanguage] = useState(handleLangSwitch(localStorage.getItem('selectedLanguage')));
    const [content, setContent] = useState(handleTransformLang(NavLang));
    const [products, setProducts] = useState(getIterableData());

    useEffect(() => {
        setContent(handleTransformLang(NavLang))
        setProducts(getIterableData())
        handleMetaTitle(headData)
    }, [language])
    return (
        <LanguageContext.Provider value={{ language, setLanguage, handleLangSwitch }}>
            <Nav />
            <main className='border-0'>
                {children}
            </main>
            <footer className="bg-blue-100 dark:bg-[#000018] border-t border-gray-300 dark:border-gray-900 px-8 lg:px-8">
                <div className="container max-w-5xl py-12 mx-auto">
                    <div className="flex justify-between">

                        <div className="grid grid-cols-1 w-full gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 ">
                            <div className='pl-4'>
                                <Link to='/' className=" text-gray-700 dark:text-white">
                                    {content.home}
                                </Link>
                            </div>
                            <div className='pl-4 flex flex-col space-y-2'>
                                <Link to='/products' className=" text-gray-700 dark:text-white">
                                    {content.products}
                                </Link>

                                <div className='flex flex-col space-y-2 '>
                                    {products.map((product, index) =>
                                        <Link key={index} to={product.link} className=" text-sm text-gray-600 dark:text-gray-400">
                                            {product.title}
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div className='pl-4'>
                                <Link to='/about' className=" text-gray-700 dark:text-white">
                                    {content.about}
                                </Link>
                            </div>
                            <div className='pl-4'>
                                <Link to='/contact' className=" text-gray-700 dark:text-white">
                                    {content.contact}
                                </Link>
                                <a href="mailto:bilgi@bilnes.com" className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">bilgi@bilnes.com</a>
                            </div>

                        </div>
                    </div>
                    <hr className="h-px my-6 border-[1px] border-black/10 dark:border-gray-800" />
                    <div className='md:flex justify-between items-center mx-4'>
                        <p className="text-center text-sm text-gray-500 dark:text-gray-400 ">© 2023 - Bilnes Bilgi Teknolojileri A.Ş. Tüm hakları saklıdır.</p>
                        <div className=' flex justify-center pt-4 md:pt-0'>
                            <a href="https://www.linkedin.com/company/bilnes-bilgi-teknolojileri-a-s/" target='_blank'
                                className=" text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                            >
                                <svg className="w-10 h-10 fill-current"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 01-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 002 2.866v10.268a.88.88 0 00.885.866h10.226a.882.882 0 00.889-.866V2.865a.88.88 0 00-.889-.864z"
                                        />
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </LanguageContext.Provider>
    )
}

function handleMetaTitle(headData) {
    const translatedData = handleTransformLang(headData);

    const titleElement = document.head.querySelector(`title`);
    if (titleElement) {
        if (translatedData.title) {
            titleElement.innerHTML = translatedData.title;
        }
    } else {
        if (translatedData.title) {
            const newTitle = document.createElement("title");
            newTitle.innerHTML= translatedData.title;
            document.head.appendChild(newTitle);
        }
    }

    const metaElement = document.head.querySelector(`meta[name="description"]`);
    if (metaElement) {
        metaElement.setAttribute("content", translatedData.description);
    } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", translatedData.description);
        document.head.appendChild(newMeta);
    }
}
