import { useEffect, useState } from "react";

export default function Location({ locationTitle, locationDesc, viewLargerMap, mapHref, mapSrc, imageSrc }) {
    const currentTime = Date.now();
    const [isAvailable, setIsAvailable] = useState(true)



    async function checkStatus() {
        if (mapSrc !== undefined) {
            const response = await fetch(mapSrc)
                .then((response) => {
                    if (response.status >= 200 || response.status <= 299) {
                        setIsAvailable(true)
                    }
                })
                .catch(() => {
                    setIsAvailable(false)
                });
        }

    }

    useEffect(() => {
        checkStatus()

    }, [])
    return (
        <ul className='grid justify-center items-center gap-4 overflow-hidden shadow-xl dark:shadow-black lg:grid-cols-2 grid-cols-1 bg-blue-100 dark:bg-indigo-950 md:rounded-[30px] rounded-[15px] mb-12'>
            <li className=''>
                <div className='flex flex-col p-4 md:p-8 lg:p-12'>
                    <p className='py-4 text-gray-950/80 dark:text-white font-semibold text-xl md:text-2xl lg:text-3xl'>
                        {locationTitle}
                    </p>
                    <p className='text-md md:text-lg lg:text-xl text-gray-950/80 dark:text-white'>
                        {locationDesc}
                    </p>
                    <a className='text-md md:text-lg lg:text-xl text-gray-900 dark:text-white pt-4' href="mailto:bilgi@bilnes.com">bilgi@bilnes.com</a>
                </div>
            </li>
            <li className='overflow-hidden relative aspect-video lg:aspect-square'>
                <div className='z-20 absolute w-full h-full bg-transparent text-transparent hover:text-gray-50/90 hover:bg-gray-950/30 text-xl md:text-3xl   transition ease-in-out delay-150 duration-300'>
                    <a
                        className='flex justify-center items-center  w-full h-full'
                        target='_blank'
                        href={mapHref}
                    >
                        <div className=''>
                            {viewLargerMap}
                        </div>
                    </a>
                </div>
                {(isAvailable) &&

                    <iframe
                        id={mapSrc}
                        src={mapSrc}
                        className=' w-full h-full aspect-video lg:aspect-square'
                        style={{ border: 0 }}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        allowFullScreen=""
                    />
                }
                {(!isAvailable) &&
                    <div className='z-10  absolute top-0 w-full h-full'>
                        <img
                            id={imageSrc}
                            className='w-full h-full object-cover'
                            src={imageSrc}
                        />
                    </div>
                }
            </li>
        </ul>
    )
}
