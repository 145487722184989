
import AnimatedComponent from '../Components/AnimatedComponent'

export default function MainSection({ title, description ,src}) {

    return (
        <>
            <div className='grid border-1 gap-12 lg:gap-24 lg:grid-cols-12 grid-cols-1 lg:py-12 md:py-8 py-6 '>

                <AnimatedComponent
                    direction={`animate-slide-left`}
                    className={`w-full h-full lg:col-span-7`} >
                    <div className='flex flex-col justify-center rounded-xl text-black dark:text-white h-full w-full '>
                        <h1 className=' text-center font-semibold mb-8 text-3xl md:text-4xl lg:text-5xl xl:text-6xl '>
                            {title}
                        </h1>
                        <p className='text-center text-lg md:text-xl lg:text-2xl'>
                            {description}
                        </p>
                    </div>
                </AnimatedComponent>

                <AnimatedComponent
                    direction={`animate-slide-right`}
                    className={`w-full h-full lg:col-span-5`} >
                    <div className=' rounded-3xl overflow-hidden'>
                        <div className='overflow-hidden aspect-square lg:aspect-[10/11] flex justify-center lg:justify-end'>
                            <img
                                className=' rounded-xl object-contain h-full'
                                src={src}
                            >
                            </img>
                        </div>
                    </div>
                </AnimatedComponent>
            </div>
        </>
    )
}
