
import AnimatedComponent from '../Components/AnimatedComponent'

export default function Solution({ src, header, description, direction, color }) {


    return (
        <div
            className={`my-8 grid border-1 gap-8 lg:grid-cols-2 grid-cols-1 `} >

            <AnimatedComponent
                direction={`${(direction == 'left') ? 'animate-slide-right' : 'animate-slide-left'}`}
                className={`${(direction == 'left') ? ' lg:order-last' : ' lg:order-first'}  `} >
                <div className='h-full'>
                    <div className={`${color} h-[250px]  md:h-[250px] lg:h-[300px] flex justify-center shadow-lg items-center p-4 md:p-5 lg:p-6 rounded-2xl md:rounded-3xl   `}>
                        <img
                            className={` object-contain  max-h-full  rounded-md `}
                            src={src}
                        />
                    </div>
                </div>
            </AnimatedComponent>

            <AnimatedComponent
                direction={`${(direction == 'right') ? 'animate-slide-right' : 'animate-slide-left'}`}
                className={`order-first ${(direction == 'left') ? ' lg:order-first' : 'lg:order-last'}`}>

                <div className='flex flex-col justify-center rounded-xl h-full w-full px-0 lg:px-6 py-4 '>
                    <h1 className='font-semibold mb-8 text-xl md:text-2xl lg:text-3xl text-black dark:text-gray-50'>
                        {header}
                    </h1>
                    <p className='text-md md:text-lg lg:text-xl text-gray-600 dark:text-gray-200'>
                        {description}
                    </p>
                </div>
            </AnimatedComponent>
        </div >

    )
}

